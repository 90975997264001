<template>
  <v-container class="px-sm-16 advanced-settings">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <h2 class="my-5">{{ $t('advancedSettings.advancedSettings') }}</h2>

        <validation-observer ref="form" v-slot="{ handleSubmit }">
          <v-form lazy-validation @submit.prevent="handleSubmit(saveSettings)">
            <v-row>
              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>{{ $t('advancedSettings.distanceUnit') }}</h3>
                      <p>{{ $t('advancedSettings.distanceUnitDescription') }}</p>
                      <v-radio-group v-model="advancedSettings.distance_unit" column @change="validateForm">
                        <v-radio :label="$t('advancedSettings.kilometers')" value="km"></v-radio>
                        <v-radio :label="$t('advancedSettings.miles')" value="mi"></v-radio>
                      </v-radio-group>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>{{ $t('advancedSettings.dateFormat') }}</h3>
                      <p>{{ $t('advancedSettings.dateFormatDescription') }}</p>
                      <v-radio-group v-model="advancedSettings.date_format" column @change="validateForm">
                        <v-radio :label="$t('advancedSettings.ddmmyyyy')" value="dd-mm"></v-radio>
                        <v-radio :label="$t('advancedSettings.mmddyyyy')" value="mm-dd"></v-radio>
                      </v-radio-group>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.mandatoryDrivings') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.mandatoryDrivingsDescription') }}
                      </p>
                      <v-checkbox
                        @change="validateForm"
                        v-model="advancedSettings.drivings_mandatory"
                        :label="$t('advancedSettings.mandatoryDrivingsDefault')"></v-checkbox>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.paidDrivings') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.paidDrivingsDescription') }}
                      </p>
                      <v-checkbox
                        @change="validateForm"
                        v-model="advancedSettings.drivings_paid"
                        :label="$t('advancedSettings.paidDrivingsDefault')"></v-checkbox>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.emailsEnabled') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.emailsEnabledDescription') }}
                      </p>
                      <v-checkbox
                        @change="validateForm"
                        v-model="advancedSettings.emails_enabled"
                        :label="$t('advancedSettings.emailsEnabledDefault')"></v-checkbox>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.passengerReviewsEnabled') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.passengerReviewsEnabledDescription') }}
                      </p>
                      <v-checkbox
                        @change="validateForm"
                        v-model="advancedSettings.passenger_reviews_enabled"
                        :label="$t('advancedSettings.passengerReviewsEnabledDefault')"></v-checkbox>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.notificationsEmail') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.notificationsEmailDescription') }}
                      </p>
                      <validation-provider rules="email|max:200" v-slot="{ errors }" name="email" ref="emailRef">
                        <v-text-field
                          type="email"
                          name="email"
                          v-model="advancedSettings.email_for_notifications"
                          clearable
                          counter="200"
                          :label="$t('advancedSettings.email')"
                          @input="handleInput"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.vatPercentage') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.vatPercentageDescription') }}
                      </p>
                      <validation-provider
                        rules="numeric_decimal|max_value:99.99|max:200"
                        v-slot="{ errors }"
                        name="vat">
                        <v-text-field
                          type="text"
                          name="vat"
                          v-model="advancedSettings.vat_percentage"
                          clearable
                          counter="200"
                          :label="$t('advancedSettings.vat') + ' (%)'"
                          @input="handleInput"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.defaultPriceType') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.defaultPriceTypeDescription') }}
                      </p>
                      <v-radio-group v-model="advancedSettings.default_price_type" column @change="validateForm">
                        <v-radio :label="$t('advancedSettings.NET')" value="NET"></v-radio>
                        <v-radio :label="$t('advancedSettings.GROSS')" value="GROSS"></v-radio>
                      </v-radio-group>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col
                cols="12"
                xl="4"
                lg="6"
                md="6"
                sm="12"
                v-if="$store.getters['auth/user']?.organisation?.country.code === 'ME'">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3 class="mb-3">
                        {{ $t('advancedSettings.articlesOfLaw') }}
                      </h3>

                      <validation-provider rules="" v-slot="errors" name="articles_of_law">
                        <vue-editor
                          v-model="advancedSettings.law_article"
                          @input="handleInput"
                          :placeholder="$t('advancedSettings.articlesOfLaw')">
                        </vue-editor>
                        <div v-if="errors.errors[0]" v class="v-text-field__details">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                {{ errors.errors[0] }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3 class="mb-3">
                        {{ $t('advancedSettings.publicBookingColor') }}
                      </h3>

                      <v-radio-group v-model="typeOfColor" column @change="validateForm">
                        <v-radio :label="$t('advancedSettings.useSystemColors')" value="system_color"></v-radio>
                        <v-radio :label="$t('advancedSettings.choseCustomColor')" value="custom_color"></v-radio>
                      </v-radio-group>

                      <v-color-picker
                        v-if="typeOfColor === 'custom_color'"
                        v-model="advancedSettings.public_booking_color"
                        dot-size="10"
                        @input="handleInput"
                        hide-mode-switch
                        clearable
                        mode="hexa"></v-color-picker>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.mapRadius') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.mapRadiusDescription') }}
                      </p>
                      <v-btn @click="openMapModal">
                        <v-icon left>mdi-map-marker</v-icon>
                        {{
                          advancedSettings.radius_center_lat && advancedSettings.radius_center_lng
                            ? $t('advancedSettings.changeLocation')
                            : $t('advancedSettings.selectLocation')
                        }}
                      </v-btn>

                      <!-- Modal -->
                      <v-dialog v-model="mapModalVisible" max-width="700px" :key="mapModalVisible">
                        <v-card>
                          <v-card-title class="info title white--text font-weight-bold">
                            <span class="text-h6">{{ $t('advancedSettings.location') }}</span>
                            <v-spacer />
                            <v-icon class="mdi mdi-close" style="color: white" @click="closeMapModal"></v-icon>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <!-- Google Map Component -->
                            <organisation-location
                              ref="googleMap"
                              :hq_lat="advancedSettings.radius_center_lat"
                              :hq_lng="advancedSettings.radius_center_lng" />
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="primary cancel" @click="closeMapModal">{{ $t('buttons.cancel') }}</v-btn>
                            <v-btn class="primary" @click="confirmLocation">{{ $t('buttons.confirm') }}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <validation-provider rules="numeric" v-slot="{ errors }" name="radius_distance">
                        <v-text-field
                          type="text"
                          name="radius_distance"
                          v-model="advancedSettings.radius_distance"
                          clearable
                          counter="200"
                          :label="
                            advancedSettings.distance_unit === 'mi'
                              ? $t('advancedSettings.radiusDistanceMi')
                              : $t('advancedSettings.radiusDistance')
                          "
                          @input="handleInput"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.publicMapRadius') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.publicMapRadiusDescription') }}
                      </p>
                      <v-btn @click="openPublicMapModal">
                        <v-icon left>mdi-map-marker</v-icon>
                        {{
                          advancedSettings.public_booking_radius_center_lat &&
                          advancedSettings.public_booking_radius_center_lng
                            ? $t('advancedSettings.changeLocation')
                            : $t('advancedSettings.selectLocation')
                        }}
                      </v-btn>

                      <!-- Modal -->
                      <v-dialog v-model="publicMapModalVisible" max-width="700px" :key="publicMapModalVisible">
                        <v-card>
                          <v-card-title class="info title white--text font-weight-bold">
                            <span class="text-h6">{{ $t('advancedSettings.location') }}</span>
                            <v-spacer />
                            <v-icon class="mdi mdi-close" style="color: white" @click="closePublicMapModal"></v-icon>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <!-- Google Map Component -->
                            <organisation-location
                              ref="googleMap"
                              :hq_lat="advancedSettings.public_booking_radius_center_lat"
                              :hq_lng="advancedSettings.public_booking_radius_center_lng" />
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="primary cancel" @click="closePublicMapModal">{{
                              $t('buttons.cancel')
                            }}</v-btn>
                            <v-btn class="primary" @click="confirmPublicBookingLocation">{{
                              $t('buttons.confirm')
                            }}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <validation-provider rules="numeric" v-slot="{ errors }" name="radius_distance">
                        <v-text-field
                          type="text"
                          name="public_booking_radius_distance"
                          v-model="advancedSettings.public_booking_radius_distance"
                          clearable
                          counter="200"
                          :label="
                            advancedSettings.distance_unit === 'mi'
                              ? $t('advancedSettings.radiusDistanceMi')
                              : $t('advancedSettings.radiusDistance')
                          "
                          @input="handleInput"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <div class="card-wrapper d-block">
                  <v-card>
                    <v-card-text>
                      <h3 class="mb-3">
                        {{ $t('advancedSettings.invoicePaymentInstructions') }}
                      </h3>

                      <validation-provider rules="" v-slot="errors" name="articles_of_law">
                        <vue-editor
                          v-model="advancedSettings.invoice_payment_instructions"
                          @input="handleInput"
                          :placeholder="$t('advancedSettings.invoicePaymentInstructions')">
                        </vue-editor>
                        <div v-if="errors.errors[0]" v class="v-text-field__details">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                {{ errors.errors[0] }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>

      <!-- <data-export /> -->

      <v-col cols="12" lg="12" md="12" sm="12">
        <h2 class="my-5">{{ $t('advancedSettings.integrations') }}</h2>

        <validation-observer ref="formGnet" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(saveGnetKey)">
            <v-row>
              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <v-tooltip top :disabled="!disabledForAffiliate">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="card-wrapper d-block" v-bind="attrs" v-on="on">
                      <v-card :disabled="disabledForAffiliate">
                        <v-card-text>
                          <h3>
                            {{ $t('advancedSettings.gnetId') }}
                          </h3>
                          <p>
                            {{ $t('advancedSettings.gnetDescription') }}
                          </p>
                          <div class="gnet-wrapper">
                            <validation-provider
                              rules="required|max:100"
                              v-slot="{ errors }"
                              name="gnet_id"
                              ref="gnetIdRef">
                              <v-text-field
                                v-model="advancedSettings.gnet_id"
                                name="gnet_id"
                                counter="100"
                                clearable
                                :label="$t('advancedSettings.gnetId')"
                                :disabled="gnetIdValid !== '' && gnetIdValid"
                                :error-messages="errors"></v-text-field>
                              <v-icon v-if="gnetIdValid !== '' && !gnetIdValid" color="red"> mdi-alert-circle </v-icon>
                              <v-icon v-if="gnetIdValid !== '' && gnetIdValid" color="green"> mdi-check-circle </v-icon>
                            </validation-provider>
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-img
                            alt="GNET"
                            class="shrink ml-1"
                            contain
                            src="@/assets/gnet.png"
                            transition="scale-transition"
                            width="78px"
                            height="31px" />
                          <v-spacer />
                          <button-submit
                            :failed="failed || (gnetIdValid !== '' && gnetIdValid)"
                            :loading="loading2"
                            buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-card>
                    </div>
                  </template>
                  <span v-if="disabledForAffiliate">
                    {{ $t('tooltips.disabledForAffiliateUser') }}
                  </span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <div class="card-wrapper">
                  <v-card>
                    <v-card-text>
                      <h3>
                        {{ $t('advancedSettings.stripe') }}
                      </h3>
                      <p>
                        {{ $t('advancedSettings.stripeDescription') }}
                      </p>
                    </v-card-text>

                    <v-card-actions>
                      <svg
                        viewBox="0 0 60 25"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="25"
                        alt="Stripe logo">
                        <title>Stripe logo</title>
                        <path
                          fill="var(--userLogoColor, #0A2540)"
                          d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z"
                          fill-rule="evenodd"></path>
                      </svg>
                      <v-spacer />
                      <v-btn
                        :disabled="loading3"
                        :loading="loading3"
                        :class="advancedSettings.stripe_connect_enabled == 1 ? 'primary red' : 'primary'"
                        text
                        type="button"
                        @click="toggleStripeConnect">
                        {{
                          advancedSettings.stripe_connect_enabled ? $t('buttons.deactivate') : $t('buttons.activate')
                        }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="12" sm="12">
                <zapier-integration :zapierId="zapierId" :zapierEnabledOld="zapierEnabled" />
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="12" sm="12">
                <integration-routes :integrationTokenOld="integrationToken" />
              </v-col>

              <v-col cols="12" xl="8" lg="12" md="12" sm="12">
                <public-link :publicLinkOld="publicLink" />
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-vue';
import ZapierIntegration from '@/components/advanced-settings/ZapierIntegration.vue';
import IntegrationRoutes from '@/components/advanced-settings/IntegrationRoutes.vue';
import PublicLink from '@/components/advanced-settings/PublicLink.vue';
import OrganisationLocation from '@/components/organisations/OrganisationLocation.vue';
// import DataExport from '@/components/advanced-settings/DataExport';
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
import debounce from 'lodash/debounce';

export default {
  name: 'UserOrganisationPage',
  components: {
    ButtonSubmit,
    VueEditor,
    ZapierIntegration,
    IntegrationRoutes,
    PublicLink,
    OrganisationLocation,
    // DataExport
  },
  data() {
    return {
      advancedSettings: {
        distance_unit: 'km',
        date_format: 'dd-mm',
        drivings_mandatory: false,
        drivings_paid: false,
        emails_enabled: false,
        email_for_notifications: '',
        vat_percentage: '',
        default_price_type: 'NET',
        gnet_id: '',
        law_article: '',
        invoice_payment_instructions: '',
        stripe_connect_enabled: false,
        public_booking_color: '#040b91',
        radius_center_lat: '',
        radius_center_lng: '',
        radius_distance: '',
        public_booking_radius_center_lat: '',
        public_booking_radius_center_lng: '',
        public_booking_radius_distance: '',
      },
      gnetExists: false,
      gnetIdValid: '',
      zapierId: '',
      zapierEnabled: false,
      integrationToken: false,
      publicLink: false,
      loading: false,
      loading2: false,
      loading3: false,
      debounceInputChange: function () {},
      typeOfColor: 'system_color',
      mapModalVisible: false,
      publicMapModalVisible: false,
    };
  },
  async created() {
    await this.loadAdvancedSettings();

    // if (this.$route.query['stripe-connect'] == 'true') {}
    if (this.$route.query['stripe-connect'] == 'false') {
      this.$store.dispatch('showSnackbar', {
        text: i18n.t('snackbar.finishStripeConnect'),
        color: '#ffeb3b',
        textColor: '#000',
      });
    }

    // we initialize function here and not in the data because we do not want to trigger it
    // when we fill the form in the loadAdvancedSettings
    this.debounceInputChange = debounce(function () {
      this.onInputStopped();
    }, 500);
  },
  computed: {},
  methods: {
    async loadAdvancedSettings() {
      await this.$store.dispatch('articlesOfLaw/getAllAdvancedSettingsForOrganization').then((res) => {
        this.advancedSettings = {
          ...res.data,
          distance_unit: res.data.distance_unit || 'km',
          date_format: res.data.date_format || 'dd-mm',
          drivings_mandatory: parseInt(res.data.drivings_mandatory) || false,
          drivings_paid: parseInt(res.data.drivings_paid) || false,
          emails_enabled: parseInt(res.data.emails_enabled) || false,
          passenger_reviews_enabled: parseInt(res.data.passenger_reviews_enabled) || false,
          stripe_connect_enabled: parseInt(res.data.stripe_connect_enabled) || false,
          public_booking_color: res.data.public_booking_color || '#040b91',
          radius_center_lat: res.data.radius_center_lat || '',
          radius_center_lng: res.data.radius_center_lng || '',
          radius_distance: res.data.radius_distance || '',
          public_booking_radius_center_lat: res.data.public_booking_radius_center_lat || '',
          public_booking_radius_center_lng: res.data.public_booking_radius_center_lng || '',
          public_booking_radius_distance: res.data.public_booking_radius_distance || '',
        };

        if (res.data.public_booking_color) {
          this.typeOfColor = 'custom_color';
        }

        if (res.data.gnet_id) {
          this.gnetExists = true;

          this.checkIfGnetIsValid();
        }

        this.integrationToken = res.data.api_auth_token;
        this.publicLink = res.data.public_booking_id;
        this.zapierId = res.data.zapier_id;
        this.zapierEnabled = parseInt(res.data.zapier_enabled);

        this.updateAdvancedSettings(res.data);
      });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('distance_unit', this.advancedSettings.distance_unit);
      formData.append('date_format', this.advancedSettings.date_format);
      formData.append('drivings_mandatory', this.advancedSettings.drivings_mandatory ? 1 : 0);
      formData.append('drivings_paid', this.advancedSettings.drivings_paid ? 1 : 0);
      formData.append('emails_enabled', this.advancedSettings.emails_enabled ? 1 : 0);
      formData.append('passenger_reviews_enabled', this.advancedSettings.passenger_reviews_enabled ? 1 : 0);
      formData.append('email_for_notifications', this.advancedSettings.email_for_notifications || '');
      formData.append('vat_percentage', this.advancedSettings.vat_percentage || '');
      formData.append('default_price_type', this.advancedSettings.default_price_type || '');
      formData.append('law_article', this.advancedSettings.law_article || '');
      formData.append('invoice_payment_instructions', this.advancedSettings.invoice_payment_instructions || '');
      if (this.typeOfColor === 'system_color') {
        formData.append('public_booking_color', '');
      } else {
        formData.append('public_booking_color', this.advancedSettings.public_booking_color || '');
      }
      formData.append('radius_center_lat', this.advancedSettings.radius_center_lat || '');
      formData.append('radius_center_lng', this.advancedSettings.radius_center_lng || '');
      formData.append('radius_distance', this.advancedSettings.radius_distance || '');
      formData.append('public_booking_radius_center_lat', this.advancedSettings.public_booking_radius_center_lat || '');
      formData.append('public_booking_radius_center_lng', this.advancedSettings.public_booking_radius_center_lng || '');
      formData.append('public_booking_radius_distance', this.advancedSettings.public_booking_radius_distance || '');

      return formData;
    },

    async saveSettings() {
      this.loading = true;
      let formData = this.createFormData();

      await this.$store
        .dispatch('articlesOfLaw/saveArticlesOfLaw', formData)
        .then((res) => {
          if (res.data.gnet_id) {
            this.gnetExists = true;
          }

          this.updateAdvancedSettings(res.data);

          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async saveGnetKey() {
      this.loading2 = true;
      this.gnetExists = true;

      await this.$store
        .dispatch('gnet/saveGnetKey', { gnet_id: this.advancedSettings.gnet_id })
        .then(() => {
          this.gnetIdValid = true;

          const user = this.$store.getters['auth/user'];

          const updatedUser = {
            ...user,
            organisation: {
              ...user.organisation,
              organisation_settings: {
                ...user.organisation.organisation_settings,
                gnet_id: this.advancedSettings.gnet_id,
              },
            },
          };
          this.$store.dispatch('auth/setUser', updatedUser);
          localStorage.setItem('LimoExpressUser', JSON.stringify(updatedUser));

          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
        })
        .catch((error) => {
          this.gnetIdValid = false;
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading2 = false;
        });
    },

    async checkIfGnetIsValid() {
      await this.$store
        .dispatch('gnet/checkHealth')
        .then((res) => {
          if (res.data === false) {
            this.gnetIdValid = false;
          } else {
            this.gnetIdValid = true;
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async toggleStripeConnect() {
      this.loading3 = true;
      let path;

      if (this.advancedSettings.stripe_connect_enabled) {
        path = 'organisations/disableStripeConnect';
      } else {
        path = 'organisations/enableStripeConnect';
      }

      await this.$store
        .dispatch(path)
        .then((res) => {
          if (res?.success) {
            if (this.advancedSettings.stripe_connect_enabled) {
              this.advancedSettings = { ...this.advancedSettings, stripe_connect_enabled: 0 };

              const user = this.$store.getters['auth/user'];

              const updatedUser = {
                ...user,
                organisation: {
                  ...user.organisation,
                  organisation_settings: {
                    ...user.organisation.organisation_settings,
                    stripe_connect_enabled: 0,
                  },
                },
              };

              this.$store.dispatch('auth/setUser', updatedUser);
              localStorage.setItem('LimoExpressUser', JSON.stringify(updatedUser));

              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.stripeDisabled'), color: 'green' });
            } else {
              window.open(res.data, '_blank');
            }
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading3 = false;
        });
    },

    updateAdvancedSettings(data) {
      const user = this.$store.getters['auth/user'];

      const updatedUser = {
        ...user,
        organisation: {
          ...user.organisation,
          organisation_settings: {
            ...user.organisation.organisation_settings,
            distance_unit: data.distance_unit,
            date_format: data.date_format,
            drivings_mandatory: data.drivings_mandatory,
            drivings_paid: data.drivings_paid,
            emails_enabled: data.emails_enabled,
            passenger_reviews_enabled: data.passenger_reviews_enabled,
            stripe_connect_enabled: data.stripe_connect_enabled,
            vat_percentage: data.vat_percentage,
            default_price_type: data.default_price_type,
            public_booking_color: data.public_booking_color,
            radius_center_lat: data.radius_center_lat,
            radius_center_lng: data.radius_center_lng,
            radius_distance: data.radius_distance,
            public_booking_radius_center_lat: data.public_booking_radius_center_lat,
            public_booking_radius_center_lng: data.public_booking_radius_center_lng,
            public_booking_radius_distance: data.public_booking_radius_distance,
          },
        },
      };

      this.$store.dispatch('auth/setUser', updatedUser);
      localStorage.setItem('LimoExpressUser', JSON.stringify(updatedUser));
    },

    handleInput() {
      this.debounceInputChange();
    },

    onInputStopped() {
      this.validateForm();
    },

    async validateForm() {
      if (await this.$refs.form.validate()) {
        this.saveSettings();
      }
    },

    openMapModal() {
      this.mapModalVisible = true;
    },
    closeMapModal() {
      this.mapModalVisible = false;
    },
    confirmLocation() {
      // Access the selected location from the map
      const location = this.$refs.googleMap.location;
      if (location) {
        this.advancedSettings.radius_center_lat = location.lat;
        this.advancedSettings.radius_center_lng = location.lng;
        this.validateForm();
      }
      this.closeMapModal();
    },
    openPublicMapModal() {
      this.publicMapModalVisible = true;
    },
    closePublicMapModal() {
      this.publicMapModalVisible = false;
    },
    confirmPublicBookingLocation() {
      // Access the selected location from the map
      const location = this.$refs.googleMap.location;
      if (location) {
        this.advancedSettings.public_booking_radius_center_lat = location.lat;
        this.advancedSettings.public_booking_radius_center_lng = location.lng;
        this.validateForm();
      }
      this.closePublicMapModal();
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.advanced-settings {
  .integration-routes-text {
    display: flex;
    align-items: baseline;
    font-size: 16px;
  }

  .card-wrapper {
    height: 100%;

    .v-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .gnet-wrapper {
    position: relative;

    i {
      position: absolute;
      right: 20px;
      top: 40%;
      transform: translateY(-40%);
    }
  }
}
</style>
