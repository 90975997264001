<template>
  <v-tooltip top :disabled="!disabledForAffiliate">
    <template v-slot:activator="{ on, attrs }">
      <div class="card-wrapper d-block" v-bind="attrs" v-on="on">
        <v-card :disabled="disabledForAffiliate">
          <v-card-text>
            <h3>
              {{ $t('advancedSettings.makePublicFormAvailable') }}
            </h3>
            <p>
              {{ $t('advancedSettings.makePublicFormAvailableDescription') }}
            </p>
            <p class="integration-routes-text">
              <v-switch
                v-model="publicLinkEnabled"
                color="green"
                hide-details
                @change="toggleOrganisationPublicRoutes"></v-switch>
            </p>
            <div v-if="publicLinkEnabled && publicLink">
              <h4>
                {{ $t('advancedSettings.link') }}
              </h4>
              <p class="pt-3 d-flex align-center" style="word-break: break-all">
                <span style="padding-bottom: 5px; font-size: 12px">
                  {{ publicLink }}
                </span>
                <v-icon class="ml-5" large @click="copyPublicLink">mdi-content-copy</v-icon>
              </p>
            </div>
            <div v-if="publicLinkEnabled && publicLinkIframe">
              <h4>
                {{ $t('advancedSettings.code') }}
              </h4>

              <v-row>
                <v-col col="3" sm="6" md="4" lg="3" xl="2">
                  <v-autocomplete
                    name="language"
                    item-text="lang"
                    item-value="abbr"
                    v-model="selectedLanguage"
                    :items="languageOptions"
                    :label="$t('advancedSettings.language')"
                    prepend-icon="mdi-web"></v-autocomplete>
                </v-col>
              </v-row>
              <p class="pt-3 d-flex align-center" style="word-break: break-all">
                <span style="padding-bottom: 5px; font-size: 12px">
                  {{ publicLinkIframe }}
                </span>
                <v-icon class="ml-5" large @click="copyPublicLinkIframe">mdi-content-copy</v-icon>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <span v-if="disabledForAffiliate">
      {{ $t('tooltips.disabledForAffiliateUser') }}
    </span>
  </v-tooltip>
</template>

<script>
import i18n from '@/i18n/i18n';
import { languageOptions } from '@/mixins/language-options';

export default {
  name: 'PublicLink',
  props: ['publicLinkOld'],
  components: {},
  data: () => ({
    publicLinkEnabled: false,
    publicLink: '',
    publicLinkIframe: '',
    selectedLanguage: 'en',
    languageOptions: languageOptions,
  }),
  created() {},
  methods: {
    async toggleOrganisationPublicRoutes() {
      await this.$store
        .dispatch('organisations/toggleOrganisationPublicLink')
        .then((res) => {
          if (res?.data?.public_booking_id) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.makePublicLinkActive'), color: 'green' });

            this.publicLink =
              process.env.VUE_APP_PUBLIC_DOMAIN + '/public/reservation-form?param=' + res?.data?.public_booking_id;

            this.updateIframe();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.makePublicLinkInactive'), color: 'green' });

            this.publicLink = '';
            this.publicLinkIframe = '';
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async copyPublicLink() {
      try {
        await navigator.clipboard.writeText(this.publicLink);
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.publicLinkCopied'), color: 'green' });
      } catch (err) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.copyPublicLink'), color: 'red' });
      }
    },
    async copyPublicLinkIframe() {
      try {
        await navigator.clipboard.writeText(this.publicLinkIframe);
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.publicLinkCopied'), color: 'green' });
      } catch (err) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.copyPublicLink'), color: 'red' });
      }
    },
    updateIframe() {
      this.publicLinkIframe =
        '<iframe src="' +
        process.env.VUE_APP_PUBLIC_DOMAIN +
        '/public/reservation-embedded-form?param=' +
        this.publicLinkOld +
        '&language=' +
        this.selectedLanguage +
        '" frameborder="0"' +
        ' width="100%" height="1000px"></iframe>';
    },
  },
  computed: {},
  watch: {
    publicLinkOld(val) {
      if (val) {
        this.publicLinkEnabled = true;

        this.publicLink = process.env.VUE_APP_PUBLIC_DOMAIN + '/public/reservation-form?param=' + this.publicLinkOld;

        this.updateIframe();
      }
    },
    selectedLanguage(val) {
      if (val) {
        this.updateIframe();
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
