<template>
  <v-tooltip top :disabled="!disabledForAffiliate">
    <template v-slot:activator="{ on, attrs }">
      <div class="card-wrapper d-block" v-bind="attrs" v-on="on">
        <v-card :disabled="disabledForAffiliate">
          <v-card-text>
            <h3>
              {{ $t('advancedSettings.makeRoutesAvailable') }}
            </h3>
            <p>
              {{ $t('advancedSettings.makeRoutesAvailableDescription') }}
            </p>
            <p class="integration-routes-text">
              <v-switch
                v-model="integrationTokenEnabled"
                color="green"
                hide-details
                @change="toggleOrganisationPublicRoutes"></v-switch>
            </p>
            <div v-if="integrationTokenEnabled && integrationToken">
              <p class="pt-3 d-flex align-center" style="word-break: break-all">
                <span style="padding-bottom: 5px; font-size: 12px">
                  {{ integrationToken }}
                </span>
                <!-- <v-icon class="xl mdi mdi-clipboard-multiple-outline ml-2" large @click="copyToClipboard"></v-icon> -->
                <v-icon class="ml-5" large @click="copyToClipboard">mdi-content-copy</v-icon>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <span v-if="disabledForAffiliate">
      {{ $t('tooltips.disabledForAffiliateUser') }}
    </span>
  </v-tooltip>
</template>

<script>
import i18n from '@/i18n/i18n';

export default {
  name: 'IntegrationRoutes',
  props: ['integrationTokenOld'],
  components: {},
  data: () => ({
    integrationTokenEnabled: false,
    integrationToken: '',
  }),
  created() {},
  methods: {
    async toggleOrganisationPublicRoutes() {
      await this.$store
        .dispatch('organisations/toggleOrganisationPublicRoutes')
        .then((res) => {
          if (res?.access_token) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.makeRoutesPublic'), color: 'green' });

            this.integrationToken = res?.access_token;
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.makeRoutesNotPublic'), color: 'green' });

            this.integrationToken = '';
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.integrationToken);
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.integrationKeyCopied'), color: 'green' });
      } catch (err) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.copyToken'), color: 'red' });
      }
    },
  },
  computed: {},
  watch: {
    integrationTokenOld(val) {
      if (val) {
        this.integrationTokenEnabled = true;
        this.integrationToken = this.integrationTokenOld;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
